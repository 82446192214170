import React, { useState } from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  mainBlue,
  mainWhite,
  mainGrey,
  mainOrange,
  lightGrey,
  screen,
  transHover,
} from "../../common/variables";
import { FaFilter } from "react-icons/fa";
import slugify from "slugify";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Wrapper = styled.div`
  color: ${mainBlue};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media ${screen.small} {
    flex-direction: row;
  }

  .filter {
    max-width: 190px;
    width: 100%;
    background-color: ${lightGrey};
    margin-right: 80px;
    display: none;
    @media ${screen.small} {
      margin-right: 40px;
      display: block;
    }
    @media ${screen.medium} {
      margin-right: 80px;
    }
    @media ${screen.xlarge} {
      margin-right: 120px;
    }

    &__icon {
      padding: 22px 28px 18px 28px;
      font-size: 1.2rem;
      width: 100%;
      display: block;
      position: relative;

      &::after {
        content: "";
        background-color: ${mainWhite};
        width: 90%;
        display: block;
        height: 1px;
        position: absolute;
        left: 50%;
        bottom: 1px;
        transform: translateX(-50%);
      }
    }

    &__list {
      li {
        font-size: 1.05rem;
        font-weight: 800;
        padding: 20px 28px;
        cursor: pointer;
        @media ${screen.xlarge} {
          font-size: 1.12rem;
        }
      }

      .selected {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-top: 20px solid transparent;
          border-right: 24px solid ${mainWhite};
          border-bottom: 20px solid transparent;
          right: 0;
        }
      }
    }

    &--sm {
      display: block;
      width: 100%;
      max-width: 1200px;
      margin-bottom: 20px;
      position: relative;
      @media ${screen.xsmall} {
        margin-bottom: 50px;
      }
      @media ${screen.small} {
        display: none;
      }

      .selected-filter {
        background-color: ${lightGrey};
        position: relative;

        &__filter-icon {
          position: absolute;
          left: 20px;
          font-size: 1rem;
          top: 50%;
          transform: translateY(-50%);
          @media ${screen.xsmall} {
            font-size: 1.3rem;
            left: 40px;
          }

          &::after {
            background-color: ${mainWhite};
            content: "";
            width: 1px;
            display: block;
            height: 26px;
            position: absolute;
            right: -19px;
            top: 50%;
            transform: translateY(-50%);
            @media ${screen.xsmall} {
              height: 30px;
              right: -39px;
            }
          }
        }

        &__label {
          display: block;
          font-weight: 800;
          padding: 15px 0;
          font-size: 0.9rem;
          text-transform: uppercase;
          margin-left: 74px;
          @media ${screen.xsmall} {
            font-size: 1.25rem;
            margin-left: 22%;
            padding: 16px 0;
          }
        }

        &__down-arrow {
          position: absolute;
          right: 15px;
          font-size: 1.85rem;
          top: calc(50% + 3px);
          transform: translateY(-50%);
          @media ${screen.xsmall} {
            font-size: 2.7rem;
            right: 20px;
            top: calc(50% + 6px);
          }
        }
      }

      .option-list {
        background-color: ${lightGrey};
        padding: 2px 0 8px 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        z-index: 10;
        box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
        @media ${screen.xsmall} {
          padding: 4px 0 16px 0;
        }

        li {
          font-size: 0.9rem;
          padding: 11px 0;
          margin-left: 74px;
          @media ${screen.xsmall} {
            font-size: 1.15rem;
            margin-left: 22%;
            padding: 12px 0;
          }
        }
      }
    }
  }

  .products {
    width: 100%;

    &__heading {
      font-size: 1.7rem;
      margin-bottom: 8px;
      @media ${screen.xsmall} {
        font-size: 2.3rem;
        margin-bottom: 25px;
      }
      @media ${screen.xlarge} {
        font-size: 2.5rem;
        margin-bottom: 32px;
      }
    }

    &__description {
      max-width: 700px;

      p {
        font-size: 1.08rem;
        margin-bottom: 18px;
        @media ${screen.xsmall} {
          font-size: 1.08rem;
          margin-bottom: 22px;
        }
        @media ${screen.xlarge} {
          font-size: 1.25rem;
          margin-bottom: 30px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      align-content: flex-start;
      @media ${screen.xsmall} {
        margin-left: -45px;
        margin-right: -45px;
        margin-top: 80px;
      }

      .each-product {
        cursor: pointer;
        width: 100%;
        margin: 0 0 35px 0;
        @media ${screen.xsmall} {
          width: calc(50% - 90px);
          margin: 0 45px 80px 45px;
        }
        @media ${screen.small} {
          margin: 0 45px 120px 45px;
          width: calc(33.33% - 90px);
        }

        &__name {
          font-size: 1.1rem;
          font-weight: 800;
          margin-bottom: 12px;
          @media ${screen.xsmall} {
            font-size: 1.2rem;
          }
          @media ${screen.xlarge} {
            font-size: 1.25rem;
          }
        }

        &__img {
          padding: 10px 20px;
          position: relative;

          img {
            z-index: 2;
          }

          &::after {
            content: "";
            position: absolute;
            background-color: rgba(166, 167, 168, 0.1);
            top: 0;
            right: 0;
            height: 100%;
            z-index: 1;
            width: 244px;
            @media ${screen.small} {
              width: 190px;
            }
            @media ${screen.medium} {
              width: calc(80px + 57%);
            }
            @media ${screen.large} {
              width: 230px;
            }
            @media ${screen.xlarge} {
              width: 244px;
            }
          }
        }

        &__btn {
          background-color: ${mainGrey};
          color: ${mainWhite};
          border: 0;
          cursor: pointer;
          font-size: 0.93rem;
          padding: 10px 0;
          transition: ${transHover};
          outline-color: ${mainBlue};
          width: 100%;
          @media ${screen.withCursor} {
            &:hover {
              background-color: ${mainOrange};
            }
          }
        }
      }
    }
  }
`;

const ContessiTab = ({
  contessi,
  addUrlSearch,
  setProductSidebar,
  setProduct,
  urlPath,
}) => {
  const {
    product_list,
    safety,
    blowingEquipments,
    plantEquipments,
    all_products_description,
    blowing_equipment_description,
    safety_description,
    plant_equipment_description,
  } = contessi;

  const [isDropdownOpen, setDropdownFilter] = useState(false);
  const [selectedTab, setTab] = useState(product_list);
  const [selectedHeading, setHeading] = useState("All Products");
  const [selectedDescription, setDescription] = useState(
    all_products_description.html
  );

  const selectCategory = (products, headings, description) => {
    setTab(products);
    setHeading(headings);
    setDescription(description);
    toggleDropdown();
  };

  const toggleDropdown = () => {
    setDropdownFilter(!isDropdownOpen);
  };

  return (
    <Wrapper>
      <div className="filter">
        <span className="filter__icon">
          <FaFilter />
        </span>
        <ul className="filter__list">
          <li
            onClick={() => {
              selectCategory(
                product_list,
                "All Products",
                all_products_description && all_products_description.html
              );
            }}
            className={selectedHeading === `All Products` ? `selected` : null}
          >
            <span>ALL PRODUCTS</span>
          </li>
          <li
            onClick={() => {
              selectCategory(
                blowingEquipments,
                "Blowing Equipment",
                blowing_equipment_description &&
                  blowing_equipment_description.html
              );
            }}
            className={
              selectedHeading === `Blowing Equipment` ? `selected` : null
            }
          >
            <span>BLOWING EQUIPMENT</span>
          </li>

          <li
            onClick={() => {
              selectCategory(
                safety,
                "Safety",
                safety_description && safety_description.html
              );
            }}
            className={selectedHeading === `Safety` ? `selected` : null}
          >
            <span>SAFETY</span>
          </li>

          <li
            onClick={() => {
              selectCategory(
                plantEquipments,
                "Plant Equipment",
                plant_equipment_description && plant_equipment_description.html
              );
            }}
            className={
              selectedHeading === `Plant Equipment` ? `selected` : null
            }
          >
            <span>PLANT EQUIPMENT</span>
          </li>
        </ul>
      </div>
      {/* ============= Mobile Filters */}
      <div className="filter filter--sm">
        <div className="selected-filter" onClick={toggleDropdown}>
          <span className="selected-filter__filter-icon">
            <FaFilter />
          </span>
          <span className="selected-filter__label">{selectedHeading}</span>
          <span className="selected-filter__down-arrow">
            {isDropdownOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </span>
        </div>
        {isDropdownOpen && (
          <ul className="option-list">
            {selectedHeading !== "All Products" && (
              <li
                onClick={() => {
                  selectCategory(
                    product_list,
                    "All Products",
                    all_products_description && all_products_description.html
                  );
                }}
              >
                ALL PRODUCTS
              </li>
            )}
            {selectedHeading !== "Blowing Equipment" && (
              <li
                onClick={() => {
                  selectCategory(
                    blowingEquipments,
                    "Blowing Equipment",
                    blowing_equipment_description &&
                      blowing_equipment_description.html
                  );
                }}
              >
                BLOWING EQUIPMENT
              </li>
            )}

            {selectedHeading !== "Safety" && (
              <li
                onClick={() => {
                  selectCategory(
                    safety,
                    "Safety",
                    safety_description && safety_description.html
                  );
                }}
              >
                SAFETY
              </li>
            )}

            {selectedHeading !== "Plant Equipment" && (
              <li
                onClick={() => {
                  selectCategory(
                    plantEquipments,
                    "Plant Equipment",
                    plant_equipment_description &&
                      plant_equipment_description.html
                  );
                }}
              >
                PLANT EQUIPMENT
              </li>
            )}
          </ul>
        )}
      </div>
      {/* ============= Mobile Filters */}
      <div className="products">
        <h2 className="products__heading">{selectedHeading}</h2>
        <div
          className="products__description"
          dangerouslySetInnerHTML={{ __html: selectedDescription }}
        />

        <ul className="products__list">
          {selectedTab.map((product) => (
            <div
              onClick={() => {
                setProduct({
                  id: product.node.id,
                  name: product.node.data.name.text,
                  description:
                    product.node.data.description &&
                    product.node.data.description.html,
                  images:
                    product.node.data.images.length && product.node.data.images,
                  column1Heading:
                    product.node.data.column_1_heading &&
                    product.node.data.column_1_heading.text,
                  column2Heading:
                    product.node.data.column_2_heading &&
                    product.node.data.column_2_heading.text,
                  column3Heading:
                    product.node.data.column_3_heading &&
                    product.node.data.column_3_heading.text,
                  column4Heading:
                    product.node.data.column_4_heading &&
                    product.node.data.column_4_heading.text,
                  column5Heading:
                    product.node.data.column_5_heading &&
                    product.node.data.column_5_heading.text,
                  column6Heading:
                    product.node.data.column_6_heading &&
                    product.node.data.column_6_heading.text,
                  column1Subheading: product.node.data.column_1_subheading,
                  column2Subheading: product.node.data.column_2_subheading,
                  column3Subheading: product.node.data.column_3_subheading,
                  column4Subheading: product.node.data.column_4_subheading,
                  column5Subheading: product.node.data.column_5_subheading,
                  column6Subheading: product.node.data.column_6_subheading,
                  tableRows: product.node.data.rows,
                  relatedProducts: product.node.data.product_list,
                });

                setProductSidebar(true);
                addUrlSearch(
                  urlPath,
                  slugify(product.node.data.name.text, {
                    replacement: "-", // replace spaces with replacement
                    lower: true, // result in lower case
                  })
                );
              }}
              className="each-product"
              key={product.node.id}
            >
              <h3 className="each-product__name">
                {product.node.data.name.text}
              </h3>
              <div className="each-product__img">
                {product.node.data.images[0].image.gatsbyImageData && (
                  <GatsbyImage
                    image={product.node.data.images[0].image.gatsbyImageData}
                    alt={product.node.data.images[0].image.alt}
                  />
                )}
              </div>
              <button className="each-product__btn">FIND OUT MORE</button>
            </div>
          ))}
        </ul>
      </div>
    </Wrapper>
  );
};

export default ContessiTab;
